import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import LinksContainer from "./LinksContainer";
import carhauler1 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 06 42 PM.jpg";
import carhauler2 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 07 07 PM.jpg";
import carhauler3 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 06 53 PM.jpg";
import carhauler4 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 15 37 PM.jpg";
import carhauler5 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 15 24 PM.jpg";
import carhauler6 from "./Assets/Car Hauler Pics/Photo May 06 2024, 5 15 53 PM.jpg";
function CarHauler() {
  return (
    <>
      <div className="pt-5">
        <h1>Car Hauler Trailers</h1>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler1}
            alt="Gooseneck trailer"
          />
          {/* <Carousel.Caption>
            <h4>Gooseneck Trailers</h4>
            <p>The best choice for heavy-duty hauling.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler6}
            alt="Equipment trailer"
          />
          {/* <Carousel.Caption>
            <h4>Equipment Trailers</h4>
            <p>Perfect for transporting machinery and tools.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler3}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h4>Utility Trailers</h4>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler2}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h4>Utility Trailers</h4>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler5}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h4>Utility Trailers</h4>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={carhauler4}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h4>Utility Trailers</h4>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      <LinksContainer />
      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="car-hauler-description text-center">
            <h2 className="text-primary">
              Built to Haul Your Valued Cars - Car Hauler Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line car hauler trailers are designed to safely and
              efficiently transport your vehicles. Whether you're a professional
              car hauler or just need a secure way to move your car across long
              distances, we have the perfect trailer for you.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">Size and Weight Specs:</h2>
              <ul>
                <p className="text-primary">
                  **Single-car hauler:**</p>
                <li>82x16 SCH, 4" Channel, 7K GVWR</li>
                <li>82x18 SCH, 4" Channel, 7K GVWR</li>
                <li>82x20 SCH, 4" Channel, 7K GVWR</li>
                <li>82x16 WCH, 4" Channel, 7K GVWR</li>
                <li>82x18 WCH, 4" Channel, 7K GVWR</li>
                <li>82x20 WCH, 4" Channel, 7K GVWR</li>
              </ul>
              <ul>
              <p className="text-primary">
                  **Tandem-axle car hauler:**
                </p>
                <li>82x20 WCH, 5" Channel, 10K GVWR</li>
                <li>82x22 WCH, 5" Channel, 10K GVWR</li>
                <li>82x24 WCH, 6" Channel, 10K GVWR</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Standard Specifications:</h2>
              <ul>
              <p className="text-primary">
              **Single-car hauler:**</p>
                <li>(2) Easy Lube 3.5K GAWR Axles</li>
                <li>2' Dovetail Multi-Leaf Double Eye Springs</li>
                <li>2" Ball A-Frame Hitch</li>
                <li>5K Jack Set Back in Tongue With Jack Foot</li>
                <li>4" Channel Frame and Wrap Tongue Slide in Ramps & Holders</li>
                <li>Standard Teardrop Fenders</li>
                <li>10 Gauge Tread Plate Floor </li>
                <li>ST205/75R15 Radial Tires and Silver Modular Rims</li>
                <li>Spare Tire Mount on Passenger Side</li>
                <li>LED Lights</li>
                <li>DOT Approved Break-Away</li>
              </ul>
              <ul>
              <p className="text-primary">
                  **Tandem-axle car hauler:**
                </p>
                <li>(2) Easy Lube 5.2K GAWR Axles</li>
                <li>2' Dovetail Multi-Leaf Double Eye Springs</li>
                <li>2 5/16" Ball A-Frame Hitch</li>
                <li>5K Jack Set Back in Tongue With Jack Foot</li>
                <li>6' Long 3" Channel Frame and Wrap Tongue Slide in Ramps & Holders</li>
                <li>HD Treadplate Fenders</li>
                <li>10 Gauge Tread Plate Floor </li>
                <li>ST235/80R16 Radial Tires and Silver Modular Rims</li>
                <li>Spare Tire Mount on Passenger Side</li>
                <li>LED Lights</li>
                <li>DOT Approved Break-Away</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Features:</h2>
              <ul>
                <li>Spare tire</li>
                <li>Stake pockets for securing cargo</li>
                <li>Tool box for storing essential equipment</li>
                <li>Lockable storage compartments</li>
                <li>Aluminum wheels for a lighter weight trailer</li>
                <li>
                  Hydraulic lift gate for effortless loading of heavy items
                </li>
                <li>E-coat corrosion protection for extended trailer life</li>
              </ul>
            </Col>
          </Row>

          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific needs and get a quote on
            your perfect utility trailer!
          </p>
        </Container>
        <CTA />
        <LinksContainer />
      </div>
    </>
  );
}

export default CarHauler;
