import React from "react";
import { Container, Carousel, Row, Col } from "react-bootstrap";
import CTA from "./CTA";
import LinksContainer from "./LinksContainer";
import equipment1 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 09 34 PM.jpg";
import equipment2 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 10 12 PM.jpg";
import equipment3 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 10 21 PM.jpg";
import equipment4 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 10 38 PM.jpg";
import equipment5 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 11 27 PM.jpg";
import equipment6 from "./Assets/Equip Trailer Pics/Photo May 06 2024, 5 12 00 PM.jpg";

function Equipment() {
  return (
    <>
      <div className="pt-5 pb-2">
        <h1>Equipment Trailers</h1>
      </div>

      <Carousel>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment1}
            alt="Gooseneck trailer"
          />
          {/* <Carousel.Caption>
            <h3>Gooseneck Trailers</h3>
            <p>The best choice for heavy-duty hauling.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment2}
            alt="Equipment trailer"
          />
          {/* <Carousel.Caption>
            <h3>Equipment Trailers</h3>
            <p>Perfect for transporting machinery and tools.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment3}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment4}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment5}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{ maxHeight: "75vh", width: "100vw", objectFit: "cover" }}
            src={equipment6}
            alt="Utility trailer"
          />
          {/* <Carousel.Caption>
            <h3>Utility Trailers</h3>
            <p>Great for moving furniture and appliances.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      <LinksContainer />
      <div className="my-5" style={{ minHeight: "300px" }}>
        <Container className="w-75 mb-4">
          <div className="equipment-trailer-description text-center">
            <h2 className="text-primary">
              Transport Your Equipment Safely - Equipment Trailers
            </h2>
            <p className="lead">
              Our top-of-the-line equipment trailers are engineered to securely
              and efficiently transport your machinery. Whether you're a
              construction professional or need a reliable way to move heavy
              equipment across long distances, we have the perfect trailer for
              your needs.
            </p>
          </div>
        </Container>
        <Container>
          <Row>
            <Col>
              <h2 className="text-primary">Size and Weight Specs:</h2>
              <ul>
                <p className="text-primary">**Single-axle equipment trailer:**</p>
                <li>
                <li>82x16 Equipment Trailer, 14K GVWR</li>
                <li>82x18 Equipment Trailer, 14K GVWR</li>
                <li>82x20 Equipment Trailer, 14K GVWR</li>
                <li>82x22 Equipment Trailer, 14K GVWR</li>
                <li>82x24 Equipment Trailer, 14K GVWR</li>
                </li>
                </ul>
               
            </Col>
            <Col>
              <h2 className="text-primary">Common Specifications:</h2>
              <ul>
              <p className="text-primary">
              **Single-car hauler:**</p>
                <li>(2) Easy Lube 7K GAWR Axles</li>
                <li>2' Dovetail Multi-Leaf Double Eye Springs</li>
                <li>2 5/16" Ball Adjustable Coupler</li>
                <li>12K Jack Set Back in Tongue With Jack Foot</li>
                <li>6" Channel Frame and Wrap Tongue Slide in Ramps & Holders</li>
                <li>3" Channel Crossmembers on 24" Centers Stand-up Ramps with Spring Assist Stake Pockets Tool Box in Tongue of Trailer</li>
                <li>Heavy Duty Treadplate Fenders</li>
                <li>ST235/80R16 Radial Tires and Silver Modular Rims</li>
                <li>Spare Tire Mount on Passenger Side</li>
                <li>LED Lights</li>
                <li>DOT Approved Break-Away</li>
              </ul>
            </Col>
            <Col>
              <h2 className="text-primary">Optional Features:</h2>
              <ul>
                <li>Spare tire and mount</li>
                <li>Toolbox for storage of equipment and accessories</li>
                <li>Winch for loading heavy machinery</li>
                <li>Dovetail for easier equipment loading</li>
                <li>Upgrade to radial tires for improved durability</li>
                <li>Custom paint and branding options</li>
                <li>Hydraulic lift for raising and lowering equipment</li>
              </ul>
            </Col>
          </Row>

          <p className="lead text-center text-primary my-5">
            Contact us today to discuss your specific hauling needs and receive
            a quote for your ideal equipment trailer!
          </p>
        </Container>

        <CTA />
        <LinksContainer />
      </div>
    </>
  );
}

export default Equipment;
