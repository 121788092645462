import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import for React Router
import { Navbar, Nav } from "react-bootstrap";
import logo from "./Assets/MTV-Logo/MTV_Logo_V4.png";

function NavBar() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewHeight = window.innerHeight;
      const threshold = 0.1 * viewHeight; // 10% of view height

      if (scrollPosition > threshold) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbar = {
    transition: "backgroundColor 0.1s ease",
  };

  const navbarScrolled = {
    backgroundColor: "rgba(255, 255, 255, 1)", // Light background color
  };

  return (
    <>
      <Navbar
        className={scrolled ? "navbarScrolled" : "navbar"}
        variant="light"
        expand="lg"
        fixed="top"
        style={scrolled ? navbarScrolled : navbar}
      >
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "50px", height: "50px" }}
            className="mx-2"
          />
          <span
            className="ms-2"
            style={{
              fontFamily: "Roboto",
            }}
          >
            Mountain Trailer Ventures, LLC
          </span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          style={{
            justifyContent: "end",
            padding: "2%",
            fontFamily: "Roboto",
            lineHeight: "1",
          }}
        >
          <Nav>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            {/* <Nav.Link as={Link} to="/products">
              Products
            </Nav.Link> */}
            <Nav.Link as={Link} to="/about">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/financing">
              Financing
            </Nav.Link>
            <Nav.Link as={Link} to="/testimonials">
              Testimonials
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavBar;
