import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function LinksContainer() {
  const links = [
    { path: "/carhauler", text: "Car Hauler" },
    { path: "/dump", text: "Dump Trailer" },
    { path: "/enclosed", text: "Enclosed Trailer" },
    { path: "/equipment", text: "Equipment Trailer" },
    { path: "/gooseneck", text: "Gooseneck Trailer" },
    // { path: "/pintlehitch", text: "Pintle Hitch Trailer" },
    { path: "/utility", text: "Utility Trailer" },
  ];

  const linksContainer = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    padding: "10px 0",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: "#fff",
  };

  const linkStyle = {
    margin: "5px",
    minWidth: "120px",
  };

  return (
    <div style={linksContainer} className="my-5">
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.path}
          onMouseEnter={(e) => (e.target.style.transform = "scale(1.2)")}
          onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
          style={linkStyle}
        >
          <Button>{link.text}</Button>
        </Link>
      ))}
    </div>
  );
}

export default LinksContainer;
